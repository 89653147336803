/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      xh: this.$route.query.xh,
      spendDetail: ''
    };
  },
  methods: {
    getXiaofeimingxi: function getXiaofeimingxi() {
      var _this = this;

      this.$api.Spend.getXiaofeimingxi({
        xh: this.xh
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this.$utils.Tools.loadHide();

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.spendDetail = data;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.getXiaofeimingxi();
  }
};